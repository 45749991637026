<template>
    <div>
        <Header/>
        <div class=" v_content">
            <div style="padding:15px;">
                <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                    <div style="text-align:left;margin-top:20px;">
                        <h5><strong>{{$t('info.header')}}</strong></h5>
                    </div>
                    <div style="text-align:left;font-size:14px;margin-top:20px;padding:10px;">
                        <strong>{{$t('info.c1_header')}}</strong>
                        <p style="">
                        {{$t('info.c1_p')}}
                        </p>
                        <ul>
                            <li v-html="$t('info.c1_p_1')"></li>
                            <li v-html="$t('info.c1_p_2')"></li>
                            <li v-html="$t('info.c1_p_3')"></li>
                            <li v-html="$t('info.c1_p_4')"></li>
                            <li v-html="$t('info.c1_p_5')"></li>
                            <li v-html="$t('info.c1_p_6')"></li>
                        </ul>
                        <p style="" v-html="$t('info.c2_p')"></p>
                    </div>
                    <b-row class="map_bar" id="table_1">
                        <b-col style="font-size:clamp(10px, 3vw, 18px)">
                            {{$t('statusbar.verygood')}}
                        </b-col>
                        <b-col style="font-size:clamp(10px, 3vw, 18px)">
                            {{$t('statusbar.good')}}
                        </b-col>
                        <b-col style="font-size:clamp(10px, 3vw, 18px)">
                            {{$t('statusbar.moderate')}}
                        </b-col>
                        <b-col style="font-size:clamp(10px, 3vw, 18px)">
                            {{$t('statusbar.startaffect')}}
                        </b-col>
                        <b-col style="font-size:clamp(10px, 3vw, 18px)">
                            {{$t('statusbar.affecthealth')}}
                        </b-col>
                    </b-row>
                    <b-row style="padding-top:10px;padding-bottom:40px;padding-left:10px;padding-right:10px;font-size:18px;">
                        <b-col style="padding:0px;padding-left:5px;">
                            <div style="height:30px;background: #3BCCFF;border-top-left-radius: 10px;border-bottom-left-radius: 10px;">
                                <label style="margin-top:2px;font-size:clamp(10px, 3vw, 18px)">0 - 25</label>
                            </div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:30px;background: #92D050;">
                                <label style="margin-top:2px;font-size:clamp(10px, 3vw, 18px)">26 - 50</label>
                            </div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:30px;background: #FFFF00;">
                                <label style="margin-top:2px;font-size:clamp(10px, 3vw, 18px)">51 - 100</label>
                            </div>
                        </b-col>
                        <b-col style="padding:0px;">
                            <div style="height:30px;background: #FFA200;">
                                <label style="margin-top:2px;font-size:clamp(10px, 3vw, 18px)">101-200</label>
                            </div>
                        </b-col>
                        <b-col style="padding:0px;padding-right:5px;">
                            <div style="height:30px;background: #F04646;border-top-right-radius: 10px;border-bottom-right-radius: 10px;">
                                <label style="margin-top:2px;font-size:clamp(10px, 3vw, 18px)">201 {{$t('above')}}</label>
                            </div>
                        </b-col>
                    </b-row>
                    <div style="text-align:left;font-size:14px;margin-top:0px;padding:10px;">
                        <strong>{{$t('info.t1_header')}}</strong>
                        <b-table-simple responsive style="margin-top:20px;text-align:center;font-size:clamp(8px, 1.5vw, 18px)">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th class="aqiClass" v-html="$t('info.aqi')"></b-th>
                                    <b-th v-html="$t('info.color')" class="colorClass"></b-th>
                                    <b-th v-html="$t('info.meaning')"></b-th>
                                    <b-th v-html="$t('info.note')"></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td>
                                        <span>0 - 25</span>
                                    </b-td>
                                    <b-td>
                                        <div class="circle_table" style="background:#3BCCFF"></div> 
                                    </b-td>
                                    <b-td>
                                        <span v-html="$t('info.t1_m1')"></span>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        <span v-html="$t('info.t1_n1')"></span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>26 - 50</span>
                                    </b-td>
                                    <b-td>
                                        <div class="circle_table" style="background:#92D050"></div> 
                                    </b-td>
                                    <b-td>
                                        <span v-html="$t('info.t1_m2')"></span>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        <span v-html="$t('info.t1_n2')"></span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>51 - 100</span>
                                    </b-td>
                                    <b-td>
                                        <div class="circle_table" style="background:#FFFF00"></div> 
                                    </b-td>
                                    <b-td>
                                        <span v-html="$t('info.t1_m3')"></span>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        <span v-html="$t('info.t1_n3')"></span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>101 - 200</span>
                                    </b-td>
                                    <b-td>
                                        <div class="circle_table" style="background:#FFA200"></div> 
                                    </b-td>
                                    <b-td>
                                        <span v-html="$t('info.t1_m4')"></span>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        <span v-html="$t('info.t1_n4')"></span>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>201 {{$t('above')}}</span>
                                    </b-td>
                                    <b-td>
                                        <div class="circle_table" style="background:#F04646"></div> 
                                    </b-td>
                                    <b-td>
                                        <span v-html="$t('info.t1_m5')"></span>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        <span v-html="$t('info.t1_n5')"></span>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                    <div style="text-align:left;font-size:14px;margin-top:0px;padding:10px;">
                        <strong>{{$t('info.c3_header')}}</strong>
                        <p v-html="$t('info.c3_p_1')" style="margin-top:10px;"></p>
                    </div>
                    <div style="text-align:left;font-size:14px;margin-top:0px;padding:10px;">
                        <strong>{{$t('info.formula_header')}}</strong>
                        <div style="padding:10px;">
                            <img src="@/assets/formula.png" height="55px"/>
                        </div>
                        <p id="table_2" v-html="$t('info.formula_p')"></p>
                    </div>
                    <div style="text-align:left;font-size:14px;margin-top:0px;padding:10px;">
                        <strong>{{$t('info.t2_header')}}</strong>
                        <b-table-simple responsive style="margin-top:20px;text-align:center;font-size:clamp(8px, 1.5vw, 16px)">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th rowspan="2" style="width:15%;">
                                        <span class="aqi_row2" style="font-size:clamp(8px, 1.5vw, 16px)">{{table2_fields[0].label}}</span>
                                    </b-th>
                                    <b-th v-html="$t('info.t2_pm25')"></b-th>
                                    <b-th v-html="$t('info.t2_pm10')"></b-th>
                                    <b-th v-html="table2_fields[3].label"></b-th>
                                    <b-th v-html="table2_fields[4].label"></b-th>
                                    <b-th v-html="table2_fields[5].label"></b-th>
                                    <b-th v-html="table2_fields[6].label"></b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th colspan="2"><span v-html="$t('info.t2_24hr')"></span></b-th>
                                    <b-th colspan="2"><span v-html="$t('info.t2_8hr')"></span></b-th>
                                    <b-th colspan="2"><span v-html="$t('info.t2_1hr')"></span></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr v-for="data, index in table2_items" :key="index" >
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:data.color}">
                                        </div>
                                        {{
                                            checkTableOver(data.aqi,index,table2_items.length)
                                        }}
                                    </b-td>
                                    <b-td style="position: relative;">
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:data.color}">
                                        </div>
                                        {{
                                            checkTableOver(data.PM25,index,table2_items.length)
                                        }}
                                    </b-td>
                                    <b-td style="position: relative;">
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:data.color}">
                                        </div>
                                        {{
                                            checkTableOver(data.PM10,index,table2_items.length)
                                        }}
                                    </b-td>
                                    <b-td style="position: relative;">
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:data.color}">
                                        </div>
                                        {{
                                            checkTableOver(data.O3,index,table2_items.length)
                                        }}
                                    </b-td>
                                    <b-td style="position: relative;">
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:data.color}">
                                        </div>
                                        {{
                                            checkTableOver(data.CO,index,table2_items.length)
                                        }}
                                    </b-td>
                                    <b-td style="position: relative;">
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:data.color}">
                                        </div>
                                        {{
                                            checkTableOver(data.NO2,index,table2_items.length)
                                        }}
                                    </b-td>
                                    <b-td style="position: relative;">
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:data.color}">
                                        </div>
                                        {{
                                            checkTableOver(data.SO2,index,table2_items.length)
                                        }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                    <div style="text-align:left;font-size:14px;">
                        <strong>{{$t('info.t2_header_dis')}}</strong>
                        <ul style="margin-top:10px;">
                            <li v-html="$t('info.t2_dis_1')"></li>
                            <li v-html="$t('info.t2_dis_2')"></li>
                            <li v-html="$t('info.t2_dis_3')"></li>
                            <li v-html="$t('info.t2_dis_4')"></li>
                            <li v-html="$t('info.t2_dis_5')"></li>
                            <li v-html="$t('info.t2_dis_6')"></li>
                        </ul>
                    </div>
                    <div id="table_3" style="text-align:left;font-size:14px;margin-top:0px;padding:10px;">
                        <strong>{{$t('info.t3_header')}}</strong>
                        <b-table-simple responsive style="margin-top:20px;text-align:center;">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th v-html="$t('info.t3_th_1')"></b-th>
                                    <b-th v-html="$t('info.t3_th_2')" style="width:200px;"></b-th>
                                    <b-th v-html="$t('info.t3_th_3')" style="width:120px;"></b-th>
                                    <b-th v-html="$t('info.t3_th_4')"></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td><span v-html="$t('info.t3_td_1')"></span></b-td>
                                    <b-td style="text-align:left">
                                        <b-form inline>
                                            <b-form-input size="sm" v-model="input_data[0]" style="width:120px;margin-right:10px;"></b-form-input> µg./m<sup>3</sup>
                                        </b-form>
                                    </b-td>
                                    <b-td>{{over500(calc_aqi(this.input_data[0],'PM25'))}}</b-td>
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:cirColor(calc_aqi(this.input_data[0],'PM25'))}"
                                        v-if="cirColor(calc_aqi(this.input_data[0],'PM25')) != ''">
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td><span v-html="$t('info.t3_td_2')"></span></b-td>
                                    <b-td style="text-align:left">
                                        <b-form inline>
                                            <b-form-input size="sm" v-model="input_data[1]" style="width:120px;margin-right:10px;"></b-form-input> µg./m<sup>3</sup>
                                        </b-form>
                                    </b-td>
                                    <b-td>{{over500(calc_aqi(this.input_data[1],'PM10'))}}</b-td>
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:cirColor(calc_aqi(this.input_data[1],'PM10'))}"
                                        v-if="cirColor(calc_aqi(this.input_data[1],'PM10')) != ''">
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td><span v-html="$t('info.t3_td_3')"></span></b-td>
                                    <b-td style="text-align:left">
                                        <b-form inline>
                                            <b-form-input size="sm" v-model="input_data[2]" style="width:120px;margin-right:10px;"></b-form-input> ppb
                                        </b-form>
                                    </b-td>
                                    <b-td>{{over500(calc_aqi(this.input_data[2],'O3'))}}</b-td>
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:cirColor(calc_aqi(this.input_data[2],'O3'))}"
                                        v-if="cirColor(calc_aqi(this.input_data[2],'O3')) != ''">
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td><span v-html="$t('info.t3_td_4')"></span></b-td>
                                    <b-td style="text-align:left">
                                        <b-form inline>
                                            <b-form-input size="sm" v-model="input_data[3]" style="width:120px;margin-right:10px;"></b-form-input> ppm
                                        </b-form>
                                    </b-td>
                                    <b-td>{{over500(calc_aqi(this.input_data[3],'CO'))}}</b-td>
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:cirColor(calc_aqi(this.input_data[3],'CO'))}"
                                        v-if="cirColor(calc_aqi(this.input_data[3],'CO')) != ''">
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td><span v-html="$t('info.t3_td_5')"></span></b-td>
                                    <b-td style="text-align:left">
                                        <b-form inline>
                                            <b-form-input size="sm" v-model="input_data[4]" style="width:120px;margin-right:10px;"></b-form-input> ppb
                                        </b-form>
                                    </b-td>
                                    <b-td>{{over500(calc_aqi(this.input_data[4],'NO2'))}}</b-td>
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:cirColor(calc_aqi(this.input_data[4],'NO2'))}"
                                        v-if="cirColor(calc_aqi(this.input_data[4],'NO2')) != ''">
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td><span v-html="$t('info.t3_td_6')"></span></b-td>
                                    <b-td style="text-align:left">
                                        <b-form inline>
                                            <b-form-input size="sm" v-model="input_data[5]" style="width:120px;margin-right:10px;"></b-form-input> ppb
                                        </b-form>
                                    </b-td>
                                    <b-td>{{over500(calc_aqi(this.input_data[5],'SO2'))}}</b-td>
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:cirColor(calc_aqi(this.input_data[5],'SO2'))}"
                                        v-if="cirColor(calc_aqi(this.input_data[5],'SO2')) != ''">
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tfoot>
                                <b-tr style="background:#E6E9EC;">
                                    <b-td><span v-html="$t('info.t3_td_7')"></span></b-td>
                                    <b-td></b-td>
                                    <b-td><span v-html="checkMaxAQI()"></span></b-td>
                                    <b-td>
                                        <div class="circle_table" 
                                        style="margin-bottom:5px;"
                                        :style="{background:color_data[6]}"
                                        v-if="color_data[6] != ''">
                                        </div>
                                    </b-td>
                                </b-tr>
                            </b-tfoot>
                        </b-table-simple>
                    </div>
                    <div style="text-align:left;font-size:14px;">
                        <strong>ค่ามาตรฐานคุณภาพอากาศในบรรยากาศ ของประเทศไทย</strong>
                        <b-table-simple responsive style="margin-top:20px;text-align:center;">
                            <b-thead head-variant="light">
                                <b-tr>
                                    <b-th rowspan="2">สารมลพิษ<br>ในบรรยากาศ<br>(Air Pollutants)</b-th>
                                    <b-th rowspan="2">ค่ามาตรฐาน<br>(Standards)<br>จะต้องไม่เกิน</b-th>
                                    <b-th rowspan="2">ค่าเฉลี่ยเวลา<br>(Averagingtime)</b-th>
                                    <b-th colspan="2">วิธีตรวจวัด</b-th>
                                </b-tr>
                                <b-tr>
                                    <b-th >ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ</b-th>
                                    <b-th >ประกาศกรมควบคุมมลพิษ</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ก๊าซคาร์บอนมอนอกไซด์ <sup>(1)</sup> <br>Carbon Monoxide (CO)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            30 ppm (34.2mg/m<sup>3</sup>)
                                        </div>
                                        <hr>
                                        <div>
                                            9 ppm (10.26mg/m<sup>3</sup>)
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            1 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            8 ชั่วโมง
                                        </div>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบนันดีสเปอร์ซีฟ อินฟราเรด ดีเทคชั่น <br>(Non-dispersive Infrared Detection)
                                    </b-td>
                                    <b-td>
                                        -
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ก๊าซซัลเฟอร์ไดออกไซด์ <sup>(1),(2),(3)</sup><br>Sulfur Dioxide (SO<sub>2</sub>)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            0.30 ppm (780 μg/m<sup>3</sup>)
                                        </div>
                                        <hr>
                                        <div>
                                            0.12 ppm (0.30 mg/m<sup>3</sup>)
                                        </div>
                                        <hr>
                                        <div>
                                            0.04 ppm (0.10 mg/m<sup>3</sup>)
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            1 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            24 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            1 ปี
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div style="text-align:left;">
                                            ระบบ ยู วี ฟลูออเรสเซน <sup>(2)</sup>
                                        </div>
                                        <hr>
                                        <div style="text-align:left;">
                                            ระบบพาราโรซานิลีน <sup>(1)</sup> (Pararosaniline)
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div style="text-align:left;">
                                            ระบบพาราโรซานิลีน 
                                        </div>
                                        <hr>
                                        <div style="text-align:left;">
                                            ระบบอุลตร้าไวโอเลต ฟลูออเรสเซน<br>(Ultraviolet Fluorescence)
                                        </div>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ก๊าซไนโตรเจนไดออกไซด์ <sup>(1),(5)</sup><br>Nitrogen Dioxide (NO<sub>2</sub>)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            0.17 ppm (0.32 mg/m<sup>3</sup>)
                                        </div>
                                        <hr>
                                        <div>
                                            0.03 ppm (0.057 mg/m<sup>3</sup>)
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            1 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            1 ปี
                                        </div>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบเคมีลูมิเนสเซน <sup>(5)</sup><br>(Chemiluminescence)
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบคาวิตี แอทเทนนูเอเต็ด เฟส ชิพ สเปกโทรสโกปี<br>(Cavity Attenuated Phase Shift Spectroscopy; CAPS)
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ก๊าซโอโซน <sup>(1),(4)</sup><br>Ozone (O<sub>3</sub>)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            0.10 ppm (0.20 mg/m<sup>3</sup>)
                                        </div>
                                        <hr>
                                        <div>
                                            0.07 ppm (0.14 mg/m<sup>3</sup>)
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            1 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            8 ชั่วโมง
                                        </div>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบเคมีลูเนสเซน <sup>(4)</sup>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบอุลตร้าไวโอเลต แอ็บซอบชั่น โฟโตเมดตรี<br>(Ultraviolet Absorption Photometry)
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ตะกั่ว <sup>(1)</sup><br>Lead (Pb)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        1.5 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 เดือน
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบอะตอมมิก แอบซอพชั่น สเปคโตรมิเตอร์ <br>(Atomic Absorption Spectrometer)
                                    </b-td>
                                    <b-td>
                                        -
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ฝุ่นละอองรวมหรือฝุ่นละออง<br>ขนาดไม่เกิน 100 ไมครอน <sup>(1),(3)</sup><br>Total Suspended<br>Particulate Matter (TSP)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            0.33 mg/m<sup>3</sup>
                                        </div>
                                        <hr>
                                        <div>
                                            0.10 mg/m<sup>3</sup>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            24 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            1 ปี
                                        </div>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบกราวิเมตริก<br>(Gravimetric)
                                    </b-td>
                                    <b-td>
                                        -
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ฝุ่นละอองขนาดไม่เกิน 10 ไมครอน <sup>(1),(3),(9)</sup><br>
                                            Particulate matter with <br>anaerodynamic diameter <br>
                                            less than or equal to a nominal<br>10micrometers (PM<sub>10</sub>)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            120 μg/m<sup>3</sup>
                                        </div>
                                        <hr>
                                        <div>
                                            50 μg/m<sup>3</sup>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            24 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            1 ปี
                                        </div>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบกราวิเมตริก <sup>(1)</sup><br>(Gravimetric)
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        - วิธีเบต้า เรดิเอชั่น แอทเทนนูเอชัน<br>(Beta Radiation Attenuation หรือ Beta Ray Attenuation)<br>
                                        - วิธีเทปเปอร์ อิลิเมนต์ ออสซิเลติง ไมโครบาลานซ์<br>(Tapered Element Oscillating Microbalance: TEOM)<br>
                                        - วิธีการกระเจิงของแสง (Light Scattering)<br>
                                        - วิธีเก็บตัวอย่างด้วยเครื่องเก็บตัวอย่างอากาศแบบไดโคโตมัส (Dichotomous Air Sampler)
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ฝุ่นละอองขนาดไม่เกิน 2.5 ไมครอน <sup>(6)</sup><br>
                                            Particulate matter with an<br>aerodynamic diameter <br>
                                            less than or equal to a nominal<br>2.5micrometers (PM<sub>2.5</sub>)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            37.5 μg/m<sup>3</sup>
                                        </div>
                                        <hr>
                                        <div>
                                            15 μg/m<sup>3</sup>
                                        </div>
                                    </b-td>
                                    <b-td>
                                        <div>
                                            24 ชั่วโมง
                                        </div>
                                        <hr>
                                        <div>
                                            1 ปี
                                        </div>
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        ระบบกราวิเมตริก<br/>(Gravimetric)
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        - วิธีเบต้า เรดิเอชัน แอทเทนนูเอชัน<br />(Beta Radiation Attenuation หรือ Beta Ray Attenuation)<br />
                                        - วิธีเทปเปอร์ อิลิเมนต์ ออสซิเลติง ไมโครบาลานซ์ (Tapered Element Oscillating Microbalance; TEOM)<br />
                                        - วิธีการกระเจิงของแสง (Light Scattering)<br />
                                        - วิธีเก็บตัวอย่างด้วยเคร่ืองเก็บตัวอย่างอากาศแบบไดโคโตมัส (Dichotomous Air Sampler) <br />
                                        และวิเคราะห์ด้วยวิธีกราวิเมตริก
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        <span>
                                            ก๊าซคาร์บอนไดซัลไฟด์ <sup>(7)</sup><br>Carbon Disulfide (CS<sub>2</sub>)
                                        </span>
                                    </b-td>
                                    <b-td>
                                        100 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        24 ชั่วโมง
                                    </b-td>
                                    <b-td style="text-align:left;">
                                        US EPA Compendium Method TO-15 <sup>(7)</sup>
                                    </b-td>
                                    <b-td >
                                        -
                                    </b-td>
                                </b-tr>
                                <b-tr style="background-color:rgb(234,236,239);">
                                    <b-td colspan="5">
                                        ค่ามาตรฐานสารอินทรีย์ระเหยง่าย (Volatile Organic Compounds; VOCs)<sup>(8)</sup>
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        1) เบนซีน<br>(Benzene) 
                                    </b-td>
                                    <b-td>
                                        1.7 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                    <b-td rowspan="9" style="text-align:center;margin:auto;">
                                        US EPA Compendium Method TO-14A<br>หรือ<br>US EPA Compendium Method TO-15
                                    </b-td>
                                    <b-td rowspan="9">
                                        -
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        2) ไวนิลคลอไรด์<br>(Vinyl Chloride)
                                    </b-td>
                                    <b-td>
                                        10 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        3) 1,2-ไดคลอโรอีเทน<br>(1,2-Dichloroethane)
                                    </b-td>
                                    <b-td>
                                        0.4 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        4) ไตรคลอโรเอทธิลีน<br>(Trichloroethylene)
                                    </b-td>
                                    <b-td>
                                        23 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        5) ไดคลอโรมีเทน<br>(Dichloromethane)
                                    </b-td>
                                    <b-td>
                                        22 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        6) 1,2-ไดคลอโรโพรเพน<br>(1,2-Dichloropropane)
                                    </b-td>
                                    <b-td>
                                        4 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        7) เตตระคลอโรเอทธิลีน<br>(Tetrachloroethylene)
                                    </b-td>
                                    <b-td>
                                        200 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        8) คลอโรฟอร์ม<br>(Chloroform)
                                    </b-td>
                                    <b-td>
                                        0.43 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>
                                        9) 1,3-บิวทาไดอีน<br>(1,3-Butadiene)
                                    </b-td>
                                    <b-td>
                                        0.33 μg/m<sup>3</sup>
                                    </b-td>
                                    <b-td>
                                        1 ปี
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                        <small style="color:gray;">หมายเหตุ: กรมควบคุมมลพิษออกประกาศวิธีตรวจวัดอื่นเพิ่มจากวิธีตรวจวัดตามประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ</small><br>
                        <small style="color:gray;margin-left:50px;">ppm คือ ส่วนในล้านส่วน</small><br>
                        <small style="color:gray;margin-left:50px;">mg/m<sup>3</sup> คือ มิลลิกรัมต่อลูกบาศก์เมตร</small><br>
                        <small style="color:gray;margin-left:50px;">μg/m<sup>3</sup> คือ ไมโครกรัมต่อลูกบาศก์เมตร</small><br>
                        <small style="color:gray;margin-left:50px;">- คือ ไม่ได้กำหนด</small><br>
                    </div>
                    <div style="text-align:left;font-size:14px;">
                        <strong>ดัดแปลงจาก</strong><br>
                        <label><sup>(1)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ ฉบับที่ 10 (พ.ศ. 2538) เรื่อง กำหนดมาตรฐานคุณภาพอากาศในบรรยากาศโดยทั่วไป ประกาศ ณ วันที่ 17 เมษายน พ.ศ. 2538</label><br>
                        <label><sup>(2)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ ฉบับที่ 21 (พ.ศ. 2544) เรื่อง กำหนดมาตรฐานค่าก๊าซซัลเฟอร์ไดออกไซด์ในบรรยากาศโดยทั่วไป ในเวลา 1 ชั่วโมง ประกาศ ณ วันที่ 9 เมษายน พ.ศ. 2544</label><br>
                        <label><sup>(3)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ ฉบับที่ 24 (พ.ศ. 2547) เรื่อง กำหนดมาตรฐานคุณภาพอากาศในบรรยากาศโดยทั่วไป ประกาศ ณ วันที่ 9 สิงหาคม พ.ศ. 2547</label><br>
                        <label><sup>(4)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ ฉบับที่ 28 (พ.ศ. 2550) เรื่อง กำหนดมาตรฐานคุณภาพอากาศในบรรยากาศโดยทั่วไป ประกาศ ณ วันที่ 10 เมษายน พ.ศ. 2550</label><br>
                        <label><sup>(5)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ ฉบับที่ 33 (พ.ศ. 2552) เรื่อง กำหนดมาตรฐานค่าก๊าซไนโตรเจนไดออกไซด์ในบรรยากาศโดยทั่วไป ประกาศ ณ วันที่ 17 มิถุนายน พ.ศ. 2552</label><br>
                        <label><sup>(6)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ เรื่อง กำหนดมาตรฐานฝุ่นละอองขนาดไม่เกิน 2.5 ไมครอน ในบรรยากาศโดยทั่วไป ประกาศ ณ วันที่ 23 มิถุนายน พ.ศ. 2565</label><br>
                        <label><sup>(7)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ เรื่อง กำหนดมาตรฐานก๊าซคาร์บอนไดซัลไฟด์ในบรรยากาศโดยทั่วไป ประกาศ ณ วันที่ 21 สิงหาคม 2560</label><br>
                        <label><sup>(8)</sup> ประกาศคณะกรรมการสิ่งแวดล้อมแห่งชาติ ฉบับที่ 30 (พ.ศ. 2550) เรื่อง กำหนดมาตรฐานค่าสารอินทรีย์ระเหยง่ายในบรรยากาศโดยทั่วไปในเวลา 1 ปี ประกาศ ณ วันที่ 14 กันยายน พ.ศ. 2550</label><br>
                        <label><sup>(9)</sup> ประกาศกรมควบคุมมลพิษ เรื่อง เครื่องวัดและวิธีตรวจวัดค่าเฉลี่ยของก๊าซหรือฝุ่นละอองในบรรยากาศในบรรยากาศโดยทั่วไประบบอื่นหรือวิธีอื่นที่กรมควบคุมมลพิษเห็นชอบ ประกาศ ณ วันที่ 2 สิงหาคม พ.ศ. 2562</label><br>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'

export default {
    name: 'info',
    components : {
        Header,
        Footer
    },
    data () {
        return{
            table2_items:[
                {aqi:'0 - 25',   color:'#3BCCFF',PM25:'0 - 15.0',   PM10:'0 - 50'   ,O3:'0 - 35'  ,CO:'0 - 4.4'   ,NO2:'0 - 60'   ,SO2:'0 - 100'},
                {aqi:'26 - 50',  color:'#92D050',PM25:'15.1 - 25.0',PM10:'51 - 80'  ,O3:'36 - 50' ,CO:'4.5 - 6.4' ,NO2:'61 - 106' ,SO2:'101 - 200'},
                {aqi:'51 - 100', color:'#FFFF00',PM25:'25.1 - 37.5',PM10:'81 - 120' ,O3:'51 - 70' ,CO:'6.5 - 9.0' ,NO2:'107 - 170',SO2:'201 - 300'},
                {aqi:'101 - 200',color:'#FFA200',PM25:'37.6 - 75.0',PM10:'121 - 180',O3:'71 - 120',CO:'9.1 - 30.0',NO2:'171 - 340',SO2:'301 - 400'},
                {aqi:'201',      color:'#F04646',PM25:'75.1',       PM10:'181',      O3:'121',     CO:'30.1',      NO2:'341',      SO2:'401'},
            ],
            table2_fields:[
                { key: 'aqi', label: 'AQI' },
                { key: 'PM25', label: 'PM<sub>2.5</sub><br>(มคก./ลบ.ม.)'},
                { key: 'PM10', label: 'PM<sub>10</sub><br>(มคก./ลบ.ม.)'},
                { key: 'O3', label: 'O<sub>3</sub><br>(ppb)'},
                { key: 'CO', label: 'CO<br>(ppm)'},
                { key: 'NO2', label: 'NO<sub>2</sub><br>(ppb)'},
                { key: 'SO2', label: 'SO<sub>2</sub><br>(ppb)'}
            ],
            input_data:['','','','','',''],
            color_data:['','','','','','',''],
            STANDARD_AQI: {
                AQI: [0, 25, 50, 100, 200],
                PM10: [0, 50, 80, 120, 180],
                PM25: [0, 15, 25, 37.5, 75],
                O3: [0, 35, 50, 70, 120],
                CO: [0, 4.4, 6.4, 9.0, 30.0],
                NO2: [0, 60, 106, 170, 340],
                SO2: [0, 100, 200, 300, 400],
                PM10_24hr: [0, 50, 80, 120, 180],
                PM25_24hr: [0, 15, 25, 37.5, 75],
                O3_8hr: [0, 35, 50, 70, 120],
                CO_8hr: [0, 4.4, 6.4, 9.0, 30.0],
                NO2_1hr: [0, 60, 106, 170, 340],
                SO2_1hr: [0, 100, 200, 300, 400]
            }
        }
    },
    computed: {

    },
    methods : {
        cirColor(aqi) {
            var aqiLevel = 0
            if (aqi > 200) aqiLevel = '#F04646';
            else if (aqi > 100) aqiLevel = '#FFA200';
            else if (aqi > 50) aqiLevel = '#FFFF00';
            else if (aqi > 25) aqiLevel = '#92D050';
            else if (aqi >= 0) aqiLevel = '#3BCCFF';
            else aqiLevel = '';
            return aqiLevel;
        },
        checkTableOver(data,index,max){
            if(index >= max-1){
                return data + ' ' + this.$t('above')
            }

            return data
        },
        checkTableAQI(data){
            if(data != "201")
            return data

            return '201 ' + this.$t('above')
        },
        calc_aqi(average, fieldName){
            average = parseFloat(average)
            if (average < 0) return "-";
            if (average == "") return "-";
            if (isNaN(average)) return "-";

            if (fieldName == "AQI")
                return "-";

            var stepAdd;
            if (fieldName == "CO") {
                average = Math.round(average*100) / 100.0;
                stepAdd = 0.1;
            }
            else if (fieldName == "PM25") {
                average = Math.round(average * 10) / 10.0;
                stepAdd = 0.1;
            }
            else {
                average = average.toFixed(0);
                stepAdd = 1.0;
            }
            //console.log("fieldName = " + fieldName + "; average = " + average + " " + stepAdd);
            var aqiDiff = 0;
            var valueDiff = 0;
            var aqiLv = 0;
            for (var i = 1; i <= 4; i++) {
                // var data = this.STANDARD_AQI[fieldName]
                //console.log("" + i + ": " + average + " <=> " + this.STANDARD_AQI[fieldName][i]);
                if (average <= this.STANDARD_AQI[fieldName][i]) {
                    average -= this.STANDARD_AQI[fieldName][i - 1] + (i == 1 ? 0 : stepAdd);
                    aqiDiff = this.STANDARD_AQI['AQI'][i] - (this.STANDARD_AQI['AQI'][i - 1] + (i == 1 ? 0 : 1));
                    valueDiff = this.STANDARD_AQI[fieldName][i] - (this.STANDARD_AQI[fieldName][i - 1] + (i == 1 ? 0 : stepAdd));

                    aqiLv = this.STANDARD_AQI['AQI'][i - 1] + (i == 1 ? 0 : 1);
                    aqiLv += (average * aqiDiff) / valueDiff;
                    let retAQI = parseInt(aqiLv.toFixed(0));
                    //console.log("" + i + ": " + average + "*" + aqiDiff + "/" + valueDiff + ' => '+ aqiLv + `(${retAQI})`);
                    return retAQI
                }
            }
            //console.log("average = "+average);
            average -= this.STANDARD_AQI[fieldName][4];

            if (fieldName == "PM25") {
                average = Math.floor(average) + 201;
            }
            else if (fieldName == "PM10") {
                average = 201 + parseInt(((average - 1) * 0.5));
            }
            else if (fieldName == "O3") {
                average = 200 + average;
            }
            else {
                average = 201;
            }
            return average > 500 ? 501 : average;
        },
        checkMaxAQI(){
            var maxVal = 0
            var maxPala = ""
            
            var val = this.calc_aqi(this.input_data[0],'PM25')
            if(val != '-'){
                if(val > maxVal){
                    maxVal = val
                    maxPala = 'PM<sub>2.5</sub>'
                }
            }
            val = this.calc_aqi(this.input_data[1],'PM10')
            if(val != '-'){
                if(val > maxVal){
                    maxVal = val
                    maxPala = 'PM<sub>10</sub>'
                }
            }
            val = this.calc_aqi(this.input_data[2],'O3')
            if(val != '-'){
                if(val > maxVal){
                    maxVal = val
                    maxPala = 'O<sub>3</sub>'
                }
            }
            val = this.calc_aqi(this.input_data[3],'CO')
            if(val != '-'){
                if(val > maxVal){
                    maxVal = val
                    maxPala = 'CO'
                }
            }
            val = this.calc_aqi(this.input_data[4],'NO2')
            if(val != '-'){
                if(val > maxVal){
                    maxVal = val
                    maxPala = 'NO<sub>2</sub>'
                }
            }
            val = this.calc_aqi(this.input_data[5],'SO2')
            if(val != '-'){
                if(val > maxVal){
                    maxVal = val
                    maxPala = 'SO<sub>2</sub>'
                }
            }
            //console.log('maxVal' , maxVal)
            if (maxVal == 0) {
                return '-'
            }
            this.color_data[6] = this.cirColor(maxVal)
            return this.over500(maxVal) + "<br>( " + maxPala + " )"
        },
        over500(data){
            if(data > 500)
                return this.$t('over') + " 500"
            return data
        }
    },
    created () {
        this.input_data=['','','','','','']
    }
}
</script>

<style lang="scss">
@media only screen and (min-width : 520px) {
    .circle_table{
        margin: auto;
        padding: 0px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }
}
@media only screen and (max-width : 520px) {
    .circle_table{
        margin: auto;
        padding: 0px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
}

.aqiClass{
    min-width: 140px;
    max-width: 180px;
}
.colorClass{
    min-width: 80px;
    max-width: 120px;
}
.aqi_row2{
    margin-top:-100px;
    padding: 0;
    font-size: 18px;
}
.vertical-center {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>